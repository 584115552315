import api from "./Axios";

export const login = async (username, password) => {
  try {
    const { data } = await api.post("/auth/login", { username, password });

    return { 
      accessToken: data.token,
      user: {
        username: data.username,
        nickname: data.nickname,
        email: data.email,
      },
    }; 
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

// 회원가입 함수
export const signup = async (userData) => {
  try {
    const response = await api.post("/auth/signup", userData);
    return response.data;
  } catch (error) {
    console.error("Signup failed:", error);
    throw error;
  }
};

export const logout = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (accessToken && userInfo?.username) {
      await api.delete("/auth/logout", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: { username: userInfo.username },
        withCredentials: true,
      });
    }
  } catch (error) {
    console.error("Logout failed:", error.message);
  } finally {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userInfo");
  }
};



