import axios from "axios";
import { logout } from "./AuthApi";

const api = axios.create({
  baseURL: "https://eb.hongfolio.shop/api",
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

// 헬퍼 함수
function clearAuthData() {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userInfo");
  document.cookie = "refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // 401 + 아직 retry가 안 된 요청이라면
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // 만약 /auth/refresh-token 요청에서 401이 발생했다면 (즉 refreshToken 만료)
      if (originalRequest.url.includes("/auth/refresh-token")) {
        // 무한 루프 방지를 위해 서버에 logout 요청을 보내지 않고 로컬 정리만
        clearAuthData();
        window.location.href = "/login";
        return Promise.reject(error);
      }

      // refresh-token 이외의 요청이 401 => refresh 시도
      try {
        const refreshResponse = await api.post("/auth/refresh-token");
        if (refreshResponse.data.accessToken) {
          const newAccessToken = refreshResponse.data.accessToken;
          
          // 새로 받은 AccessToken 저장
          localStorage.setItem("accessToken", newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          
          // 실패했던 요청 재시도
          return api(originalRequest);
        }
      } catch (refreshError) {
        // 재발급 자체가 실패하면 로컬에서만 정리 후 로그인 페이지로 이동
        clearAuthData();
        window.location.href = "/login";
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default api;
