import React, { useEffect, useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import api from "../api/Axios";
import "./MainPage.css";
import "./Page.css";

function GraduationPage() {
  const { userInfo } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get("/posts/category/portfolio", {
          params: { subCategory: "graduation"},
        });
    
        setPosts(response.data);
      } catch (error) {
        console.error("Failed to fetch posts:", error);
      }
    };
  
    fetchPosts();
  }, [userInfo]);

  return (
    <div className="main-page">
      {/* 뒤로가기 버튼 */}
      <button className="back-button" onClick={() => navigate("/categoryselect")}>
        &lt; 뒤로가기
      </button>
      <main className="post-section">
        <h2>Node.js, React 영상 검색 서비스 개발</h2>
        {posts.length > 0 ? (
          <div className="post-list">
            <ul>
              {posts.map((post) => (
                <li key={post.id}>
                  {/* 제목 클릭 시 해당 게시물 상세 페이지로 이동 */}
                  <Link to={`/category/${post.category}/${post.id}?subCategory=graduation`}>{post.title}</Link>
                </li>
          ))}
        </ul>
          </div>
        ) : (
          <p>게시물이 없습니다.</p>
        )}
        <div>
          <button onClick={() => navigate("/createpost", {
            state: { category: "portfolio", subCategory: "graduation" },
          })}>글 작성하기</button>
        </div>
      </main>
    </div>
  );
}

export default GraduationPage;
